import React from "react";
import "../../styles/nosotros.css";
import "animate.css";

export const SobreNosotros = () => {
  return (
    <div
      className="conainer as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd"
      style={{ minHeight: "100vh", marginBottom: "70px" }}
    >
      <div className="as54s98df7er98g7erw984cwe">
        <h1 style={{ color: "white", marginTop: "40px" }}>
          Sobre Graham´s Project
        </h1>
      </div>
      <div
        className="container as65d489f7889we7f89ewwe"
        style={{ paddingTop: "70px" }}
      >
        <div className="s56d4a9s8d7we987vw98v7e894ewe2356">
          <h1 style={{ color: "black", marginBottom: "20px" }}>
            Sobre Nosotros
          </h1>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            Graham Project es la empresa líder en remodelación y venta de bienes
            raíces en Castellón de la Plana. Con un enfoque moderno y resultados
            comprobados, somos tu mejor opción para invertir.
          </p>
          <h3
            className="asd65sd4frer78text"
            style={{ color: "black", marginBottom: "20px" }}
          >
            Nuestros Resultados
          </h3>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            <ul>
              <li>Viviendas vendidas en tiempo récord</li>
              <li>Viviendas construidas (Vendidas y en Construcción)</li>
            </ul>
          </p>
        </div>
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe2356"
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="sdf5d4erge4rg98e7rg98er49t87ge65r4fwe897rwe98r4wer98we7"></div>
        </div>
      </div>
      <div className="dfsd5f4t7897webackasf">
        <h1 style={{ textAlign: "center" }}>
          G r a h a m ´ s <br />P r o j e c t{" "}
        </h1>
      </div>
      <div className="container as65d489f7889we7f89ewwe">
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe2356"
          style={{ padding: "20px" }}
        >
          <div className="df654g89er7tg98er7fw89e4e987wfe98fw4e98we7"></div>
        </div>
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe2356"
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h1 style={{ color: "black", marginBottom: "20px" }}>
            Transforma tu futuro con nosotros
          </h1>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            Descubre nuestras oportunidades de inversión y obtén resultados
            excepcionales.
          </p>
          <h3
            className="asd65sd4frer78text"
            style={{ color: "black", marginBottom: "20px" }}
          >
            Por qué Elegirnos
          </h3>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            <ul>
              <li>Proyectos con acabados de lujo</li>
              <li>Ubicaciones privilegiadas en el centro</li>
              <li>Inversiones seguras con control en tiempo real</li>
            </ul>
          </p>
        </div>
      </div>
      {/* Letras que se van a mover */}

      <div className="sdf564rt98h7r987trcsa">
        <div className="container a5s6d5f4g89y7th98wrf4w9e84trhwf48e">
          <div className="sd654dr98gfw7e897r89t7gerfwe">
            <h1 style={{ color: "white" }}>Por qué Elegirnos en Castellón</h1>
            <p
              style={{ color: "white" }}
              className="s5d64f987tythre8wfqe8gtyuras54dtext2"
            >
              La empresa emergente n.º 1 en compra y remodelación de bienes
              raíces en Castellón de la Plana, con un gran potencial de
              crecimiento y un enfoque innovador.
            </p>
          </div>
          <div className="sd654dr98gfw7e897r89t7gerfwe2">
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>
                  2 viviendas vendidas en tiempo récord
                </h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Mostrando nuestra eficacia en el mercado local
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>4 viviendas construidas</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  2 vendidas rápidamente y 2 en construcción
                </p>
              </div>
            </div>
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>
                  Últimas viviendas vendidas en tiempo récord
                </h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Mostrando nuestra eficacia en el mercado local
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>4 viviendas construidas</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  2 vendidas rápidamente y 2 en construcción
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
