import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/BlogNoticias.css";

export const BlogNoticias = () => {
  const blogsData = [
    {
      id: 1,
      fecha: "24 de Julio, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Controla Tu Inversión en Tiempo Real",
      descripcion:
        "Descubre cómo nuestra plataforma te permite llevar el control total de tu inversión inmobiliaria en tiempo real. Inicia sesión para gestionar y monitorear tu proyecto con facilidad.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721847047/Graham%20Project/urp07strth28xcsewvwm.jpg')",
    },
    {
      id: 2,
      fecha: "30 de Julio, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Beneficios de Invertir en Castellón",
      descripcion:
        "Explora las razones por las que Castellón de la Plana es el lugar ideal para tus inversiones inmobiliarias. Desde su crecimiento económico hasta su atractivo para los inversores.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721846797/Graham%20Project/u0u7a4fug9cjlfsqgmy9.jpg')",
    },
    {
      id: 3,
      fecha: "5 de Agosto, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Tendencias en Remodelación de Viviendas",
      descripcion:
        "Mantente al tanto de las últimas tendencias en remodelación de viviendas. Aprende cómo las renovaciones inteligentes pueden aumentar el valor de tu propiedad y maximizar tus retornos.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721846798/Graham%20Project/zeguqtrntkxma1wa8gnk.jpg')",
    },
    {
      id: 4,
      fecha: "5 de Agosto, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Sabes cuando necesitas una reforma?",
      descripcion:
        "Podrías decir que necesitas una reforma porque si, ¿pero realmente sabes cuando necesitas la reforma?, aquí te diremos como poder identificar que necesitas una reforma, y como Graham Project te puede ayudar.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721846798/Graham%20Project/zeguqtrntkxma1wa8gnk.jpg')",
    },
  ];
  return (
    <div
      className="as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd"
      style={{ minHeight: "100vh", marginBottom: "70px" }}
    >
      <div className="as54s98df7er98g7erw984cwe">
        <h1 style={{ color: "white", marginTop: "40px" }}>Graham's Blog</h1>
      </div>
      <div className="container d-flex blogs-container mt-5 mb-6">
        {/* Última noticia en una columna separada */}
        {blogsData.slice(-1).map((blog) => (
          <div className="wedw8efe9c4ec9e4c w-100 d-flex justify-content-center">
            <div key={blog.id} className="center-blog">
              <div
                className="blog-imageprincipal"
                style={{
                  backgroundImage: blog.imagen,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "15px",
                  alignItems: "end",
                  display: "flex",
                }}
              ></div>
              <div className="blog-details">
                <h2 style={{ color: "#0a306a" }}>{blog.titulo}</h2>
                <p className="ny-tbrt84ve94h89ytb4v89er">
                  {blog.descripcion.slice(0, 300)}...
                </p>
                <Link
                  to={`/blog/detalle/${blog.id}`}
                  className="btn btn-ds98sd4f8d4-sd594csdc489d"
                >
                  Leer Más{" "}
                  <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
        {/* Renderizar la última noticia en una columna separada */}
        {blogsData.slice(0, -1).map((blog) => (
          <div key={blog.id} className="blog-card">
            <div
              className="blog-image"
              style={{
                backgroundImage: blog.imagen,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "15px 0px 15px 0px",
                alignItems: "end",
                display: "flex",
                width: "100%",
              }}
            ></div>
            <div className="blog-details">
              <h2 className="s-wd5we984fw9e84t98rb4v">{blog.titulo}</h2>
              <p className="ny-tbrt84ve94h89ytb4v89er">
                {blog.descripcion.slice(0, 100)}...
              </p>
              <Link
                to={`/blog/detalle/${blog.id}`}
                className="btn btn-ds98sd4f8d4-sd594csdc489d"
              >
                Leer Más{" "}
                <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
