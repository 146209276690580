import React, { useContext, useEffect, useState, useRef } from "react";
import "../../styles/home4sup.css";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Link } from "react-router-dom";
import "../../styles/home4sup.css";
import "../../styles/home3.css";
import "../../styles/BlogNoticias.css";

export const Grahamproject = () => {
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: useDeviceSize(),
      spacing: 15,
      origin: "center",
    },
    mode: "free-snap",
    duration: 0.5,
  });

  function useDeviceSize() {
    const [sizeValue, setSizeValue] = useState(getSizeValue());

    function getSizeValue() {
      const width = window.innerWidth;
      if (width < 768) {
        // Suposición para teléfonos
        return 2;
      } else if (width >= 768 && width < 1024) {
        // Suposición para tablets
        return 4.5;
      } else {
        // Suposición para computadores
        return 6.5;
      }
    }

    useEffect(() => {
      function handleResize() {
        setSizeValue(getSizeValue());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return sizeValue;
  }

  const slides = [
    {
      id: 1,
      img: "https://res.cloudinary.com/dirpdlfbu/video/upload/v1713802448/INTRAVIALCA/u0u7uag3zbgqj3oldwyg.mp4",
      title: "La Alcaldesa de Caracas",
      subtitle: "Continuando con estos objetivos, nos complace compartirles..",
    },
    {
      id: 2,
      img: "https://res.cloudinary.com/dirpdlfbu/video/upload/v1713802073/INTRAVIALCA/re0q5bcjcl9kdhzrboso.mp4",
      title: "Plan Caracas Bella",
      subtitle: "Belleza, salud, compromiso y transporte...",
    },
  ];

  const slide = [
    {
      id: 1,
      img: "https://res.cloudinary.com/dirpdlfbu/image/upload/v1713909746/CARACAS/skfze05lpavgppiq22d2.png",
      title: "La Alcaldesa de Caracas",
      subtitle: "Continuando con estos objetivos, nos complace compartirles..",
    },
    {
      id: 2,
      img: "https://res.cloudinary.com/dirpdlfbu/image/upload/v1713909746/CARACAS/skfze05lpavgppiq22d2.png",
      title: "Plan Caracas Bella",
      subtitle: "Belleza, salud, compromiso y transporte...",
    },
    {
      id: 3,
      img: "https://res.cloudinary.com/dirpdlfbu/image/upload/v1713909746/CARACAS/skfze05lpavgppiq22d2.png",
      title: "Plan Caracas Bella",
      subtitle: "Belleza, salud, compromiso y transporte...",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef2, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const blogsData = [
    {
      id: 1,
      fecha: "24 de Julio, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Controla Tu Inversión en Tiempo Real",
      descripcion:
        "Descubre cómo nuestra plataforma te permite llevar el control total de tu inversión inmobiliaria en tiempo real. Inicia sesión para gestionar y monitorear tu proyecto con facilidad.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721847047/Graham%20Project/urp07strth28xcsewvwm.jpg')",
    },
    {
      id: 2,
      fecha: "30 de Julio, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Beneficios de Invertir en Castellón",
      descripcion:
        "Explora las razones por las que Castellón de la Plana es el lugar ideal para tus inversiones inmobiliarias. Desde su crecimiento económico hasta su atractivo para los inversores.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721846797/Graham%20Project/u0u7a4fug9cjlfsqgmy9.jpg')",
    },
    {
      id: 3,
      fecha: "5 de Agosto, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Tendencias en Remodelación de Viviendas",
      descripcion:
        "Mantente al tanto de las últimas tendencias en remodelación de viviendas. Aprende cómo las renovaciones inteligentes pueden aumentar el valor de tu propiedad y maximizar tus retornos.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721846798/Graham%20Project/zeguqtrntkxma1wa8gnk.jpg')",
    },

    {
      id: 4,
      fecha: "10 de Agosto, 2024",
      ubicacion: "Castellón de la Plana, España",
      titulo: "Historias de Éxito de Nuestros Inversores",
      descripcion:
        "Lee historias inspiradoras de inversores que han trabajado con Graham Project. Descubre cómo transformaron sus inversiones en éxitos tangibles y los beneficios obtenidos.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1721846798/Graham%20Project/mnrelprn3ulxbvyxophd.jpg')",
    },
  ];

  return (
    <div className="as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd">
      <>
        <div className="navigation-wrapper" style={{ position: "relative" }}>
          <div
            ref={sliderRef2}
            className="keen-slider as65d489we7g897yuty9n8bev7wwexs"
            style={{ height: "83vh" }}
          >
            <div className="keen-slider__slide number-slide1 as67et987hr98th7ber98v4ewr98f7we98d4te98r">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h6 className="as56d4w8e9f7eruy8j7hrsh1">
                    Crece con Graham´s
                  </h6>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    La plataforma número 1 en Castellón para monitorear y
                    gestionar tus inversiones inmobiliarias en tiempo real.
                    Invierte con Nosotros!
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger">
                    <video
                      src="https://res.cloudinary.com/dirpdlfbu/video/upload/v1726409733/Graham%20Project/ytdzdw7qwd5ymarsb2qc.mp4"
                      autoPlay
                      muted
                      loop
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide number-slide2">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h1 className="as56d4w8e9f7eruy8j7hrsh1">
                    Maximiza tus Retornos
                  </h1>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Con Graham´s Project, observa el crecimiento de tus
                    inversiones y aumenta tus beneficios. Control total en tus
                    manos.
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger">
                    <video
                      src="https://res.cloudinary.com/dirpdlfbu/video/upload/v1726409733/Graham%20Project/ytdzdw7qwd5ymarsb2qc.mp4"
                      autoPlay
                      muted
                      loop
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "15px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide number-slide3">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h1 className="as56d4w8e9f7eruy8j7hrsh1">
                    Proyectos en Tiempo Real
                  </h1>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Conoce el avance de cada proyecto en tiempo real. Desde la
                    compra hasta la remodelación y venta, todo a tu alcance.
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger">
                    <video
                      src="https://res.cloudinary.com/dirpdlfbu/video/upload/v1726409733/Graham%20Project/ytdzdw7qwd5ymarsb2qc.mp4"
                      autoPlay
                      muted
                      loop
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "15px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide number-slide4">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h1 className="as56d4w8e9f7eruy8j7hrsh1">
                    Transparencia y Confianza
                  </h1>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Nuestra plataforma ofrece transparencia en cada etapa del
                    proceso, brindándote confianza y seguridad en tu inversión.
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger">
                    <video
                      src="https://res.cloudinary.com/dirpdlfbu/video/upload/v1726409733/Graham%20Project/ytdzdw7qwd5ymarsb2qc.mp4"
                      autoPlay
                      muted
                      loop
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "15px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide number-slide5">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h1 className="as56d4w8e9f7eruy8j7hrsh1">
                    Éxito Garantizado
                  </h1>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Con Graham Project, tu éxito está asegurado. Nuestro equipo
                    te acompaña en cada paso del camino hacia tus objetivos
                    financieros.
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger">
                    <video
                      src="https://res.cloudinary.com/dirpdlfbu/video/upload/v1726409733/Graham%20Project/ytdzdw7qwd5ymarsb2qc.mp4"
                      autoPlay
                      muted
                      loop
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "15px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            })}
          </div>
        )}
      </>

      <div className="sdf564rt98h7r987trcsa">
        <div className="container a5s6d5f4g89y7th98wrf4w9e84trhwf48e">
          <div className="sd654dr98gfw7e897r89t7gerfwe">
            <h1 style={{ color: "white" }}>Por qué Elegirnos en Castellón</h1>
            <p
              style={{ color: "white" }}
              className="s5d64f987tythre8wfqe8gtyuras54dtext2"
            >
              La empresa emergente n.º 1 en compra y remodelación de bienes
              raíces en Castellón de la Plana, con un gran potencial de
              crecimiento y un enfoque innovador.
            </p>
          </div>
          <div className="sd654dr98gfw7e897r89t7gerfwe2">
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>
                  2 viviendas vendidas en tiempo récord
                </h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Mostrando nuestra eficacia en el mercado local
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>4 viviendas construidas</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  2 vendidas rápidamente y 2 en construcción
                </p>
              </div>
            </div>
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>
                  Últimas viviendas vendidas en tiempo récord
                </h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Mostrando nuestra eficacia en el mercado local
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>4 viviendas construidas</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  2 vendidas rápidamente y 2 en construcción
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-align-center sd6f498et7rrg98ewf4w98e4fwe">
        <h1
          style={{ paddingBottom: "20px", textAlign: "center", color: "black" }}
        >
          Encuentra tu Proyecto Adecuado
        </h1>
        <div style={{ maxWidth: "750px" }}>
          <p style={{ textAlign: "center", color: "black" }}>
            En Graham Project, ofrecemos una variedad de oportunidades de
            inversión inmobiliaria en Castellón de la Plana. Encuentra el
            proyecto que mejor se adapte a tus necesidades y expectativas, y
            comienza a transformar tu futuro con nosotros. Aquí te presentamos
            cuatro proyectos destacados:
          </p>
        </div>
        <div className="pasd56s4df89we7f98wef48egwef">
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we m-0">
            <h4>Apartamento Moderno en el Centro</h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Un apartamento completamente renovado en el corazón de Castellón,
              ideal para alquileres a corto y largo plazo.
            </p>
            <p className="m-0">Proyectos Desde</p>
            <h2>€150.000</h2>
            <p>/Duración alrededor de 12 meses</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Ubicación céntrica
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Completa con acabados de lujo
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Transporte público y servicios
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Alto retorno de inversión
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/Login"
                className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s2"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we2">
            <div className="sddfg56e4r8g9w7e8f9q4wd8qw7">
              <p className="sd654sd8f9g7dsf98g7af9w8e4 m-0">Más Solicitado</p>
            </div>
            <h4 style={{ marginTop: "10px" }}>
              Apartamento Moderno en el Centro
            </h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Un apartamento completamente renovado en el corazón de Castellón,
              ideal para alquileres a corto y largo plazo.
            </p>
            <p className="m-0">Proyectos Desde</p>
            <h2>€150.000</h2>
            <p>/Duración alrededor de 12 meses</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Ubicación céntrica
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Completa con acabados de lujo
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Transporte público y servicios
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Alto retorno de inversión
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/Login"
                className="btn a-sd4q98dq-sdas98d4as-btn22 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s22"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we">
            <h4>Apartamento Moderno en el Centro</h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Un apartamento completamente renovado en el corazón de Castellón,
              ideal para alquileres a corto y largo plazo.
            </p>
            <p className="m-0">Proyectos Desde</p>
            <h2>€150.000</h2>
            <p>/Duración alrededor de 12 meses</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Ubicación céntrica
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Completa con acabados de lujo
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Transporte público y servicios
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Alto retorno de inversión
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/Login"
                className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s2"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we">
            <h4>Apartamento Moderno en el Centro</h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Un apartamento completamente renovado en el corazón de Castellón,
              ideal para alquileres a corto y largo plazo.
            </p>
            <p className="m-0">Proyectos Desde</p>
            <h2>€150.000</h2>
            <p>/Duración alrededor de 12 meses</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Ubicación céntrica
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Completa con acabados de lujo
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Transporte público y servicios
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Alto retorno de inversión
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/Login"
                className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s2"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
        </div>
      </div>

      <div className="container d-flex blogs-container mt-5 mb-6">
        {/* Última noticia en una columna separada */}
        {blogsData.slice(-1).map((blog) => (
          <div className="wedw8efe9c4ec9e4c w-100 d-flex justify-content-center">
            <div key={blog.id} className="center-blog">
              <div
                className="blog-imageprincipal"
                style={{
                  backgroundImage: blog.imagen,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "15px",
                  alignItems: "end",
                  display: "flex",
                }}
              >
                {/* <div className="asijmv-x59z4e">{blog.fecha}</div> */}
              </div>
              <div className="blog-details">
                <h2 style={{ color: "black" }}>{blog.titulo}</h2>
                <p className="ny-tbrt84ve94h89ytb4v89er">
                  {blog.descripcion.slice(0, 300)}...
                </p>
                <Link
                  to={`/blog/detalle/${blog.id}`}
                  className="btn btn-ds98sd4f8d4-sd594csdc489d"
                >
                  Leer Más{" "}
                  <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
        {/* Renderizar la última noticia en una columna separada */}
        {blogsData.slice(0, -1).map((blog) => (
          <div key={blog.id} className="blog-card">
            <div
              className="blog-image"
              style={{
                backgroundImage: blog.imagen,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "15px 0px 15px 0px",
                alignItems: "end",
                display: "flex",
                width: "100%",
              }}
            >
              {/* <div className="asijmv-x59z4e2">{blog.fecha}</div> */}
            </div>
            <div className="blog-details">
              <h2 className="s-wd5we984fw9e84t98rb4v">{blog.titulo}</h2>
              <p className="ny-tbrt84ve94h89ytb4v89er">
                {blog.descripcion.slice(0, 100)}...
              </p>
              <Link
                to={`/blog/detalle/${blog.id}`}
                className="btn btn-ds98sd4f8d4-sd594csdc489d"
              >
                Leer Más{" "}
                <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="sd4f5sd65f4er89h7er89gwe8f4we">
        <div className="container sd4sdf654g8r9th7t98ybh9er84er98ge9r84we98fwe">
          <div className="sdf56s4df89er798erg49er87f98rsd1884">
            <h1>Deja tu Correo!</h1>
            <p>Para enterarte de todos las inversiones que puedes hacer!</p>
          </div>
          <div className="sdf56s4df89er798erg49er87f98rsd18842">
            <div className="input-group-login bg-light">
              <input
                className="inputDeLogin"
                type="email"
                name="email"
                required
              />
              <label htmlFor="text">Correo Electrónico</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}
