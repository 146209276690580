import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../../store/appContext";
import { Link, useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import ApexCharts from "react-apexcharts";
import "../../../styles/home4sup.css";
import "../../../styles/SolicitudActivaciones.css";

export const UserHome = () => {
  return (
    <div className="container-fluid contarinerGeneralUserHomejs">
      <div className="container asklnduiashndaijsnxansxsaas">
        <i
          className="fa-solid fa-circle-exclamation"
          style={{ fontSize: "100px" }}
        ></i>
        <h1 style={{ color: "rgba(128, 128, 128, 0.697)" }}>
          ¿Listo para invertir con Nosotros?
        </h1>
        <p style={{ color: "rgba(128, 128, 128, 0.697)" }}>
          Invertir con nosotros es el primer paso hacia un futuro financiero más
          sólido y seguro.
        </p>
      </div>
    </div>
  );
};
