import React, { useContext, useState } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/home4sup.css";
import "../../../../styles/RegistroCps.css";

const MySwal = withReactContent(Swal);

export const Estadisticas = () => {
  const { store } = useContext(Context);
  const [companies, setCompanies] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado de carga
  const [formData, setFormData] = useState({
    cps_type_route: "",
    cps_company_name: "",
    cps_company_rif: "",
    cps_number: "",
    cps_due_date: "",
    cps_modality: "",
    cps_tipology: "",
    cps_route_number: "",
    cps_parada: "",
    cps_route: "",
    cps_final_destination: "",
    cps_shift_time: [""],
    cps_status: "",
    cps_observation: "",
    turnohora_escalafon: "",
  });

  const handleShiftTimeChange = (index, time) => {
    const newShiftTimes = [...formData.cps_shift_time];
    newShiftTimes[index] = time;
    setFormData({ ...formData, cps_shift_time: newShiftTimes });
  };

  const addShiftTime = () => {
    setFormData({
      ...formData,
      cps_shift_time: [...formData.cps_shift_time, ""], // Añade un nuevo input vacío
    });
  };

  const removeShiftTime = (index) => {
    const newShiftTimes = formData.cps_shift_time.filter(
      (_, idx) => idx !== index
    );
    setFormData({ ...formData, cps_shift_time: newShiftTimes });
  };

  const renderCompanyList = (companies) => {
    return companies
      .map(
        (company) => `
          <button id="company-${company.id}" class="swal2-selectable btn iasbduiqnwdqwjhndiquwhds m-0" style="margin: 5px; cursor: pointer;" onclick="handleCompanySelect('${company.company_name}', '${company.id}', '${company.company_rif}')">
            ${company.company_name}
            <p class="m-0">${company.company_rif}</p>
          </button>
          <div class="asdw-w9d84c9w8e4dcd2"></div>`
      )
      .join("");
  };

  const selectCompany = async () => {
    MySwal.fire({
      title: "Cargando empresas...",
      html: "Por favor, espere mientras se cargan las empresas.",
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/companys"
      );
      setCompanies(response.data);
      MySwal.close();

      MySwal.fire({
        title: (
          <h4 style={{ margin: "0" }}>
            <i class="fa-solid fa-user-tie"></i> Empresas de Transporte
          </h4>
        ),
        html: `
          <input type="text" id="search-box" placeholder="Buscar empresa..." style="width: 100%; margin-bottom: 10px;">
          <div id="company-list">${renderCompanyList(response.data)}</div>`,
        didRender: () => {
          const searchBox = document.getElementById("search-box");
          searchBox.addEventListener("input", (e) => {
            const searchTerm = e.target.value.toLowerCase();
            const filteredCompanies = response.data.filter(
              (company) =>
                company.company_name.toLowerCase().includes(searchTerm) ||
                company.company_rif.toLowerCase().includes(searchTerm)
            );
            document.getElementById("company-list").innerHTML =
              renderCompanyList(filteredCompanies);
            attachCompanySelectHandlers(filteredCompanies);
          });
          attachCompanySelectHandlers(response.data);
        },
        preConfirm: () => {
          return document.getElementById("search-box").value;
        },
      });
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al cargar las empresas.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const attachCompanySelectHandlers = (companies) => {
    companies.forEach((company) => {
      const button = document.getElementById(`company-${company.id}`);
      if (button) {
        button.addEventListener("click", () => {
          handleCompanySelect(
            company.company_name,
            company.id,
            company.company_rif
          );
        });
      }
    });
  };

  window.handleCompanySelect = (name, id, rif) => {
    setFormData({
      ...formData,
      cps_company_name: name,
      cps_company_rif: rif,
    });
    Swal.close();
  };

  const selectDestinations = async () => {
    MySwal.fire({
      title: "Cargando destinos...",
      html: "Por favor, espere mientras se cargan los destinos.",
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/destinations"
      );
      setDestinations(response.data);
      MySwal.close();

      let filteredDestinations = response.data; // Inicializa con la lista completa

      const updateSearchResults = (searchTerm) => {
        filteredDestinations = response.data.filter((destination) =>
          destination.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        updateDestinationList();
      };

      const updateDestinationList = () => {
        const destinationsHtml = filteredDestinations
          .map(
            (destination) => `
              <button id="destination-${destination.name}" class="swal2-selectable btn iasbduiqnwdqwjhndiquwhds m-0" style="margin: 5px; cursor: pointer;">
              ${destination.name}
            </button>
            <div class="asdw-w9d84c9w8e4dcd2"></div>
              `
          )
          .join("");
        document.getElementById("swal2-content").innerHTML = destinationsHtml;
        attachDestinationClickHandlers(filteredDestinations);
      };

      const attachDestinationClickHandlers = (destinations) => {
        destinations.forEach((destination) => {
          const button = document.getElementById(
            `destination-${destination.name}`
          );
          if (button) {
            button.addEventListener("click", () => {
              updateRoute(destination.name);
              Swal.close(); // Cerrar el modal después de seleccionar un destino
            });
          }
        });
      };

      MySwal.fire({
        title: "Seleccione los destinos",
        html: `
              <input type="text" id="search-box" placeholder="Buscar..." style="width: 100%; margin-bottom: 10px;" onkeyup="updateSearchResults(this.value)">
              <div id="swal2-content"></div>
          `,
        confirmButtonText: "Cerrar",
        didRender: () => {
          updateDestinationList(); // Llama inicialmente para poblar con todos los destinos
          window.updateSearchResults = updateSearchResults; // Hace la función accesible globalmente para el input
        },
        didOpen: () => {
          document.getElementById("search-box").focus(); // Enfoca automáticamente el campo de búsqueda
        },
        willClose: () => {
          window.updateSearchResults = undefined; // Limpia la función del objeto window después de cerrar
        },
      });
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al cargar los destinos.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const updateRoute = (destinationName) => {
    setFormData((prevFormData) => {
      let destinationsArray = prevFormData.cps_route
        .split(", ")
        .filter((dest) => dest.trim() !== "");
      if (!destinationsArray.includes(destinationName)) {
        destinationsArray.push(destinationName);
      }
      return {
        ...prevFormData,
        cps_route: destinationsArray.join(", "),
        cps_final_destination: destinationsArray[destinationsArray.length - 1], // Actualiza el destino final
      };
    });
  };

  const removeLastDestination = () => {
    setFormData((prevFormData) => {
      let destinationsArray = prevFormData.cps_route.split(", ");
      if (destinationsArray.length > 1) {
        destinationsArray.pop();
      }
      return {
        ...prevFormData,
        cps_route: destinationsArray.join(", "),
        cps_final_destination: destinationsArray.length
          ? destinationsArray[destinationsArray.length - 1]
          : "", // Asegurar que se actualiza el destino final
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "cps_parada") {
      if (value === "IDA-TERMINAL-LA-BANDERA") {
        // Asegurar que "Terminal La Bandera" esté al principio si se selecciona esta parada
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          cps_route:
            "TERMINAL LA BANDERA" +
            (prevData.cps_route.includes("TERMINAL LA BANDERA")
              ? prevData.cps_route.slice(19)
              : ""),
          cps_final_destination: "TERMINAL LA BANDERA", // Asegurar que se actualiza el destino final también
        }));
      }
      if (value === "TOKEN") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          cps_route: "",
          cps_final_destination: "", // Asegurar que se actualiza el destino final también
        }));
      } else {
        // Permite cambiar libremente entre paradas y asegúrate de eliminar "TERMINAL LA BANDERA" si se elige "Token"
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          cps_route: prevData.cps_route.replace("TERMINAL LA BANDERA, ", ""),
          cps_final_destination: prevData.cps_route
            .replace("TERMINAL LA BANDERA, ", "")
            .split(", ")[0], // Ajustar el destino final
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Evitar múltiples envíos

    setIsSubmitting(true);
    MySwal.fire({
      title: "Cargando...",
      html: "Enviando la solicitud, por favor espere...",
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    // Validación adicional para cps_shift_time
    if (
      formData.turnohora_escalafon !== "escalafon" &&
      formData.cps_shift_time.every((time) => time === "")
    ) {
      mostrarAlerta2("Debe proporcionar al menos una hora de salida");
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.post(process.env.BACKEND_URL + "/api/registerCPS", formData, {
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
      });

      setFormData({
        cps_type_route: "",
        cps_company_name: "",
        cps_company_rif: "",
        cps_number: "",
        cps_due_date: "",
        cps_modality: "",
        cps_tipology: "",
        cps_route_number: "",
        cps_parada: "",
        cps_route: "",
        cps_final_destination: "",
        cps_shift_time: [""],
        cps_status: "",
        cps_observation: "",
        turnohora_escalafon: "",
      });
      mostrarAlerta1();
      console.log("Nueva CPS agregada correctamente");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la CPS";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>CPS Registrada con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar la CPS</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const proximamente = () => {
    MySwal.fire({
      title: <p>Próximamente Disponible</p>,
      html: <p>Disfruta Próximamente de poder editar este registro</p>,
      icon: "warning",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const displayPermisosRegistrados = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getAllCPS",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Permisos Registrados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const filteredData = data;
        const content = (searchTerm) => {
          const filteredData = data.filter(
            (item) =>
              item.cps_company_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.cps_final_destination
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.cps_route.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.id
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.cps_route_number
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          );
          return filteredData
            .map(
              (item) => `
              <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; justify-content: center; align-items: center; flex-direction: column; display: flex; background-color: ${item.cps_status === "ACTIVO" ? "#0080001c" : "#ff00001a"}">
                <p><strong>ID:</strong> ${item.id}</p>
                <p><strong>Tipo de Servicio:</strong> ${item.cps_type_route}</p>
                <p><strong>Empresa:</strong> ${item.cps_company_name}</p>
                <p><strong>Ruta ${item.cps_route_number}:</strong> ${item.cps_route}</p>
                <p><strong>Destino Final:</strong> ${item.cps_final_destination}</p>
                <div style="display: flex; justify-content: space-between; margin-top: 10px; width: 70%">
                  <div style="cursor: pointer; font-size: 30px" id="editCPS-${item.id}"><i class="fa-solid fa-pen"></i></div>
                  <div style="cursor: pointer; font-size: 30px" id="changeCPSStatus-${item.id}" data-status="${item.cps_status}"><i class="fa-solid fa-toggle-${item.cps_status === "ACTIVO" ? "on" : "off"}"></i></div>
                  <div style="cursor: pointer; font-size: 30px" id="deleteCPS-${item.id}"><i class="fa-solid fa-trash"></i></div>
                </div>
              </div>
              `
            )
            .join("");
        };

        MySwal.fire({
          title: "Permisos Registrados",
          html: `
            <input type="text" id="search-box" placeholder="Buscar..." style="width: 100%; margin-bottom: 10px;" />
            <div id="swal2-content">${content("")}</div>
          `,
          confirmButtonText: "Cerrar",
          didRender: () => {
            document
              .getElementById("search-box")
              .addEventListener("input", (e) => {
                const searchTerm = e.target.value.toLowerCase();
                document.getElementById("swal2-content").innerHTML =
                  content(searchTerm);
                attachPermissionHandlers(filteredData);
              });
            attachPermissionHandlers(data);
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener permisos registrados", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener permisos registrados. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const attachPermissionHandlers = (permissions) => {
    permissions.forEach((item) => {
      const changeButton = document.getElementById(
        `changeCPSStatus-${item.id}`
      );
      const editButton = document.getElementById(`editCPS-${item.id}`);
      const deleteButton = document.getElementById(`deleteCPS-${item.id}`);

      if (changeButton) {
        changeButton.addEventListener("click", () =>
          changeCPSStatus(item.id, item.cps_status)
        );
      }
      if (editButton) {
        editButton.addEventListener("click", () => proximamente());
      }
      if (deleteButton) {
        deleteButton.addEventListener("click", () => confirmDeleteCPS(item.id));
      }
    });
  };

  const confirmDeleteCPS = (id) => {
    MySwal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCPS(id);
      }
    });
  };

  const changeCPSStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === "ACTIVO" ? "INACTIVO" : "ACTIVO";
    try {
      await axios.patch(
        `${process.env.BACKEND_URL}/api/cps/${id}/status`,
        { cps_status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      displayPermisosRegistrados(); // Refresca la lista
    } catch (error) {
      console.error("Error al cambiar el estado de la CPS", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al cambiar el estado de la CPS. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const editCPS = (item) => {
    const shiftTimes = item.cps_shift_time ? item.cps_shift_time : [""];

    setFormData({
      cps_type_route: item.cps_type_route,
      cps_company_name: item.cps_company_name,
      cps_company_rif: item.cps_company_rif,
      cps_number: item.cps_number,
      cps_due_date: item.cps_due_date,
      cps_modality: item.cps_modality,
      cps_tipology: item.cps_tipology,
      cps_route_number: item.cps_route_number,
      cps_parada: item.cps_parada,
      cps_route: item.cps_route,
      cps_final_destination: item.cps_final_destination,
      cps_shift_time: shiftTimes,
      cps_status: item.cps_status,
      cps_observation: item.cps_observation,
      turnohora_escalafon: item.turnohora_escalafon,
    });

    MySwal.fire({
      title: "Editar CPS",
      html: `
        <form id="editCPSForm" class="formularioDeLogin w-100">
          <div class="aoshduiahsdkasnmdasdas">
            <div class="input-group-login2">
              <input class="inputDeLogin" type="text" name="cps_company_name" required value="${item.cps_company_name}" />
              <label>Empresa de Transporte</label>
            </div>
            <div class="input-group-login2">
              <input class="inputDeLogin" type="text" name="cps_company_rif" required value="${item.cps_company_rif}" />
              <label>Rif de la Empresa</label>
            </div>
            <div class="input-group-login2">
              <select class="inputDeLogin" name="cps_parada" required>
                <option value="IDA-TERMINAL-LA-BANDERA" ${item.cps_parada === "IDA-TERMINAL-LA-BANDERA" ? "selected" : ""}>IDA TERMINAL LA BANDERA</option>
                <option value="TOKEN" ${item.cps_parada === "TOKEN" ? "selected" : ""}>TOKEN</option>
              </select>
              <label>Parada</label>
            </div>
          </div>
          <div class="aoshduiahsdkasnmdasdas">
            <div class="input-group-login2">
              <select class="inputDeLogin" name="cps_type_route" required>
                <option value="RL" ${item.cps_type_route === "RL" ? "selected" : ""}>RUTAS LARGAS</option>
                <option value="RC" ${item.cps_type_route === "RC" ? "selected" : ""}>RUTAS CORTAS</option>
              </select>
              <label>Tipo de Servicio</label>
            </div>
            <div class="input-group-login2">
              <input class="inputDeLogin" type="text" name="cps_number" required value="${item.cps_number}" />
              <label>Número CPS</label>
            </div>
            <div class="input-group-login2">
              <input class="inputDeLogin" type="date" name="cps_due_date" required value="${item.cps_due_date}" />
              <label>Fecha de Vencimiento</label>
            </div>
            <div class="input-group-login2">
              <select class="inputDeLogin" name="cps_modality" required>
                <option value="COLECTIVO-INTERURBANO" ${item.cps_modality === "COLECTIVO-INTERURBANO" ? "selected" : ""}>COLECTIVO INTERURBANO</option>
                <option value="PP-INTERURBANO" ${item.cps_modality === "PP-INTERURBANO" ? "selected" : ""}>POR PUESTO INTERURBANO</option>
                <option value="PP-SUB-URBANO" ${item.cps_modality === "PP-SUB-URBANO" ? "selected" : ""}>POR PUESTO SUBURBANO</option>
              </select>
              <label>Modalidad</label>
            </div>
          </div>
          <div class="aoshduiahsdkasnmdasdas">
            <div class="input-group-login2">
              <select class="inputDeLogin" name="cps_tipology" required>
                <option value="AUTOBUSES" ${item.cps_tipology === "AUTOBUSES" ? "selected" : ""}>AUTOBUSES</option>
                <option value="MINIBUSES" ${item.cps_tipology === "MINIBUSES" ? "selected" : ""}>MINIBUSES</option>
                <option value="MINIBUSES-AND-CINCO-PUESTOS" ${item.cps_tipology === "MINIBUSES-AND-CINCO-PUESTOS" ? "selected" : ""}>MINIBUSES Y CINCO PUESTOS</option>
                <option value="YUTONG" ${item.cps_tipology === "YUTONG" ? "selected" : ""}>YUTONG</option>
              </select>
              <label>Tipología</label>
            </div>
            <div class="input-group-login2">
              <select class="inputDeLogin" name="turnohora_escalafon" required>
                <option value="turno_hora" ${item.turnohora_escalafon === "turno_hora" ? "selected" : ""}>Turno hora</option>
                <option value="escalafon" ${item.turnohora_escalafon === "escalafon" ? "selected" : ""}>Escalafón</option>
              </select>
              <label>Turno Hora o Escalafón</label>
            </div>
            <div class="input-group-login2">
              <input class="inputDeLogin" type="text" name="cps_route_number" required value="${item.cps_route_number}" />
              <label>Número de Ruta</label>
            </div>
          </div>
          <div class="aoshduiahsdkasnmdasdas">
            <div class="input-group-login2" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
              <textarea class="inputDeLogin" name="cps_route" readonly style="cursor: pointer;">${item.cps_route}</textarea>
              <label>Ruta</label>
              <button type="button" class="btn sa6s5as4d89q7w89r4g9er7btn98r7we-qw4ew" style="margin-top: 10px;" onclick="removeLastDestination()">Eliminar Último Agregado</button>
            </div>
          </div>
          <div class="aoshduiahsdkasnmdasdas">
            <div class="input-group-login2">
              ${shiftTimes
                .map(
                  (time, index) => `
                <div key=${index} style="display: flex; align-items: center; margin-bottom: 10px;">
                  <input type="time" value="${time}" class="inputDeLogin" />
                  <button type="button" class="btn sa6s5as4d89q7w89r4g9er7btn98r7we-qw4ew2" style="margin-left: 10px;">
                    <i class="fa-regular fa-trash-can"></i>
                  </button>
                </div>
              `
                )
                .join("")}
              <button type="button" class="btn sa6s5as4d89q7w89r4g9er7btn98r7we-qw4ew" style="margin-top: 10px;" onclick="addShiftTime()">Añadir hora de salida</button>
            </div>
            <div class="input-group-login2">
              <select class="inputDeLogin" name="cps_status" required>
                <option value="ACTIVO" ${item.cps_status === "ACTIVO" ? "selected" : ""}>ACTIVO</option>
                <option value="INACTIVO" ${item.cps_status === "INACTIVO" ? "selected" : ""}>INACTIVO</option>
              </select>
              <label>Estatus</label>
            </div>
          </div>
          <div class="aoshduiahsdkasnmdasdas">
            <div class="input-group-login2">
              <textarea class="inputDeLogin" name="cps_observation">${item.cps_observation}</textarea>
              <label>Observación (Opcional)</label>
            </div>
          </div>
        </form>
      `,
      confirmButtonText: "Guardar Cambios",
      preConfirm: () => {
        const form = document.getElementById("editCPSForm");
        const formData = new FormData(form);
        const updatedData = {};
        formData.forEach((value, key) => {
          updatedData[key] = value;
        });
        return updatedData;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.put(
            `${process.env.BACKEND_URL}/api/cps/${item.id}`,
            result.value,
            {
              headers: {
                Authorization: `Bearer ${store.token}`,
              },
            }
          );
          displayPermisosRegistrados(); // Refresca la lista
        } catch (error) {
          console.error("Error al actualizar la CPS", error);
          MySwal.fire({
            title: "Error",
            html: `<p>Error al actualizar la CPS. Por favor, inténtelo de nuevo más tarde.</p>`,
            icon: "error",
            confirmButtonText: "Cerrar",
          });
        }
      }
    });
  };

  const deleteCPS = async (id) => {
    try {
      await axios.delete(`${process.env.BACKEND_URL}/api/cps/${id}`, {
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
      });
      displayPermisosRegistrados(); // Refresca la lista
    } catch (error) {
      console.error("Error al eliminar la CPS", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al eliminar la CPS. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Agregar Nueva Ruta
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cps_company_name"
                required
                onClick={selectCompany}
                value={formData.cps_company_name}
                style={{ cursor: "pointer" }}
              />
              <label>Empresa de Transporte</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cps_company_rif"
                value={formData.cps_company_rif}
                required
                readOnly
              />
              <label>Rif de la Empresa</label>
            </div>

            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cps_number"
                required
                onChange={handleChange}
                placeholder="ejemplo: CPS-18-5648"
              />
              <label>Número CPS</label>
            </div>
          </div>

          {/* Aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii */}
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="cps_due_date"
                value={formData.cps_due_date}
                required
                onChange={handleChange}
              />
              <label>Fecha de Vencimiento</label>
            </div>

            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="cps_modality"
                onChange={handleChange}
                value={formData.cps_modality}
                required
              >
                <option value="" disabled>
                  Selecciona una Modalidad
                </option>
                <option value="COLECTIVO-INTERURBANO">
                  COLECTIVO INTERURBANO
                </option>
                <option value="PP-INTERURBANO">POR PUESTO INTERURBANO</option>
                <option value="PP-SUB-URBANO">POR PUESTO SUBURBANO</option>
              </select>
              <label>Modalidad</label>
            </div>

            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="cps_tipology"
                onChange={handleChange}
                value={formData.cps_tipology}
                required
              >
                <option value="" disabled>
                  Selecciona una tipología
                </option>
                <option value="AUTOBUSES">AUTOBUSES</option>
                <option value="MINIBUSES">MINIBUSES</option>
                <option value="MINIBUSES-AND-CINCO-PUESTOS">
                  MINIBUSES Y CINCO PUESTOS
                </option>
                <option value="YUTONG">YUTONG</option>
              </select>
              <label>Tipología</label>
            </div>
          </div>

          {/* Aqiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii */}

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cps_route_number"
                required
                value={formData.cps_route_number}
                onChange={handleChange}
                placeholder="ejemplo: 005"
              />
              <label>Número de Ruta</label>
            </div>
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="cps_parada"
                onChange={handleChange}
                value={formData.cps_parada}
                required
              >
                <option value="" disabled>
                  Selecciona una Parada
                </option>
                <option value="IDA-TERMINAL-LA-BANDERA">
                  IDA TERMINAL LA BANDERA
                </option>
                <option value="TOKEN">TOKEN</option>
              </select>
              <label>Parada</label>
            </div>
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="cps_type_route"
                onChange={handleChange}
                value={formData.cps_type_route}
                required
              >
                <option value="" disabled>
                  Selecciona una Tipo de Servicio
                </option>
                <option value="RL">RUTAS LARGAS</option>
                <option value="RC">RUTAS CORTAS</option>
              </select>
              <label>Tipo de Servicio</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cps_final_destination"
                onChange={handleChange}
                value={formData.cps_final_destination}
                readOnly
              />
              <label>Destino Final</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div
              className="input-group-login2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <textarea
                className="inputDeLogin"
                name="cps_route"
                onClick={selectDestinations}
                value={formData.cps_route}
                readOnly // Solo lectura
                style={{ cursor: "pointer" }}
              />
              <label>Ruta</label>
              <button
                type="button"
                className="btn sa6s5as4d89q7w89r4g9er7btn98r7we-qw4ew"
                onClick={removeLastDestination}
                onChange={handleChange}
                style={{ marginTop: "10px" }}
              >
                Eliminar Último Agregado
              </button>
            </div>
          </div>

          {/* Aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii */}

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="turnohora_escalafon"
                onChange={handleChange}
                value={formData.turnohora_escalafon}
                required
              >
                <option value="" disabled>
                  Selecciona
                </option>
                <option value="turno_hora">Turno hora</option>
                <option value="escalafon">Escalafón</option>
              </select>
              <label>Turno Hora o Escalafón</label>
            </div>

            <div className="input-group-login2">
              {formData.cps_shift_time.map((time, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <input
                    type="time"
                    value={time}
                    onChange={(e) =>
                      handleShiftTimeChange(index, e.target.value)
                    }
                    className="inputDeLogin"
                    required={formData.turnohora_escalafon !== "escalafon"} // Cambia dinamicamente el requerido
                  />
                  <button
                    onClick={() => removeShiftTime(index)}
                    className="btn sa6s5as4d89q7w89r4g9er7btn98r7we-qw4ew2"
                    style={{ marginLeft: "10px" }}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </button>
                </div>
              ))}
              <button
                onClick={addShiftTime}
                style={{ marginTop: "10px" }}
                className="btn sa6s5as4d89q7w89r4g9er7btn98r7we-qw4ew"
              >
                Añadir hora de salida
              </button>
            </div>
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="cps_status"
                onChange={handleChange}
                value={formData.cps_status}
                required
              >
                <option value="" disabled>
                  Selecciona un Estatus
                </option>
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </select>
              <label>Estatus</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="text"
                name="cps_observation"
                onChange={handleChange}
              />
              <label>Observación (Opcional)</label>
            </div>
          </div>
          <button
            type="submit"
            className="btn buttonCargadeDatosDeAddDriver"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Cargando..." : "Agregar Ruta"}
          </button>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={displayPermisosRegistrados}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Permisos Registrados
        </button>
      </div>
    </div>
  );
};
