import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Envivo = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    code: "",
    name: "",
  });
  const [destinations, setDestinations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerDestination",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        code: "",
        name: "",
      });

      MySwal.fire({
        title: "Destino Registrado",
        text: "El destino ha sido registrado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      fetchDestinations(); // Update the list of destinations
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el destino.";

      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchDestinations = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getDestinations",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Destinos Registrados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        setDestinations(data);

        const filteredDestinations = data.filter(
          (dest) =>
            dest.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            dest.code.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const content = filteredDestinations
          .map(
            (item) => `
            <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
              <p><strong>Código:</strong> ${item.code}</p>
              <p><strong>Nombre:</strong> ${item.name}</p>
            </div>
          `
          )
          .join("");

        MySwal.fire({
          title: "Destinos Registrados",
          html: `
            <input type="text" id="searchQuery" placeholder="Buscar por código o nombre" style="margin-bottom: 10px; width: 100%; padding: 5px;">
            <div id="destinations-list">${content}</div>
          `,
          confirmButtonText: "Cerrar",
          didOpen: () => {
            const searchInput = document.getElementById("searchQuery");
            searchInput.addEventListener("input", (e) => {
              setSearchQuery(e.target.value);
              const filteredData = data.filter(
                (dest) =>
                  dest.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  dest.code.toLowerCase().includes(e.target.value.toLowerCase())
              );

              const filteredContent = filteredData
                .map(
                  (item) => `
                <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
                  <p><strong>Código:</strong> ${item.code}</p>
                  <p><strong>Nombre:</strong> ${item.name}</p>
                </div>
              `
                )
                .join("");

              document.getElementById("destinations-list").innerHTML =
                filteredContent;
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener destinos registrados", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener destinos registrados. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Destinos de Transporte
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="code"
                required
                onChange={handleChange}
                value={formData.code}
              />
              <label>Código</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="name"
                required
                onChange={handleChange}
                value={formData.name}
              />
              <label>Nombre</label>
            </div>
          </div>

          <button className="buttonCargadeDatosDeLogin" type="submit">
            Registrar <i className="fa-solid fa-arrow-right"></i>
          </button>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchDestinations}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Destinos Registrados
        </button>
      </div>
    </div>
  );
};
