import React, { useContext, useEffect, useState, useRef } from "react";
import "../../styles/contactanos.css";

export const Contactanos = () => {
  return (
    <div
      className="as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd"
      style={{ minHeight: "100vh", marginBottom: "70px" }}
    >
      <div className="as54s98df7er98g7erw984cwe">
        <h1 style={{ color: "white", marginTop: "40px" }}>Contáctanos</h1>
      </div>
      <div
        className="container as65d489f7889we7f89ewwe"
        style={{ paddingTop: "70px" }}
      >
        <div className="s56d4a9s8d7we987vw98v7e894ewe">
          <h1 style={{ color: "black", marginBottom: "20px" }}>
            Nuestra Información de Contacto
          </h1>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            Estás cada vez más cerca de cumplir tus metas llevando un total
            control de tu negocio
          </p>
          <div
            className="sdf65s4d8f9s79f8w7e8ht4rwe"
            style={{ marginTop: "20px" }}
          >
            <div className="sdferg4er98few498f4we">
              <i className="fa-solid fa-phone-volume sd564fsd98f79d8f798hh7te89rwe"></i>
            </div>
            <div className="sdferg4er98few498f4we2">
              <h4 style={{ color: "black" }}>Números de Teléfono:</h4>
              <p style={{ color: "black" }}>
                Movil y Whatsapp: +34 624000000 // +34 624000000
              </p>
            </div>
          </div>
          <div
            className="sdf65s4d8f9s79f8w7e8ht4rwe"
            style={{ marginTop: "40px" }}
          >
            <div className="sdferg4er98few498f4we">
              <i className="fa-regular fa-envelope sd564fsd98f79d8f798hh7te89rwe"></i>
            </div>
            <div className="sdferg4er98few498f4we2">
              <h4 style={{ color: "black" }}>Correo Electrónico:</h4>
              <p style={{ color: "black" }}>contacto@grahamproject.uk</p>
            </div>
          </div>
          <div
            className="sdf65s4d8f9s79f8w7e8ht4rwe"
            style={{ marginTop: "40px" }}
          >
            <div className="sdferg4er98few498f4we">
              <i className="fa-solid fa-location-dot sd564fsd98f79d8f798hh7te89rwe"></i>
            </div>
            <div className="sdferg4er98few498f4we2">
              <h4 style={{ color: "black" }}>Somos Agencia Online:</h4>
              <p style={{ color: "black" }}>
                Lo que nos da facilidad a la vez de tener servicio 24/7
              </p>
            </div>
          </div>
        </div>
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe"
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d779563.0577500128!2d-0.7373990371957728!3d40.249793218793116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5ff38bcf33ee77%3A0x302af6ed721c9f0!2sCastell%C3%B3n!5e0!3m2!1ses-419!2ses!4v1726328350809!5m2!1ses-419!2ses"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="d56fs4df98ew7fwemaps"
          />
        </div>
      </div>
    </div>
  );
};
