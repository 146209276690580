import React, { useEffect, useState } from "react";
import "../../styles/navbar.css";
import { Link, useLocation } from "react-router-dom";
import animationData from "../../../../nuevo.json";
import Lottie from "react-lottie";
import "animate.css";
import "../../styles/material-kit.css";
import "../../styles/material-kit.min.css";
import "../../styles/nucleo-icons.css";
import "../../styles/nucleo-svg.css";

export const Navbar = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [scrolling, setScrolling] = useState(false);
  const [togglerOpen, setTogglerOpen] = useState(false);
  const [animationInProgress, setAnimationInProgress] = useState(false);
  const [progress, setProgress] = useState(0.5);
  const [showPointer, setShowPointer] = useState(true);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    initialSegment: [0, progress * 130], // Assuming 46 frames total
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseMenu = () => {
    setTogglerOpen(false);
  };

  const handleTogglerClick = () => {
    setTogglerOpen(!togglerOpen);

    if (!animationInProgress) {
      setAnimationInProgress(true);

      // Inicia o detiene la animación según el estado del menú
      const animationFrame = togglerOpen ? 0 : 46;
      setTimeout(() => {
        setProgress(togglerOpen ? 0.5 : 1); // Set progress to 0.5 when closing, 1 when opening
        setAnimationInProgress(false);
      }, animationFrame);
    }
  };

  const logoImage =
    location.pathname === "/" ? (
      <img
        src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1721843642/Graham%20Project/kvnzkh71ua51uzhwr4bm.png"
        className="img98765461"
        alt="Logo"
      />
    ) : (
      <img
        src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1721843642/Graham%20Project/kvnzkh71ua51uzhwr4bm.png"
        className="img98765461"
        alt="Other Logo"
      />
    );

  const linkColor = location.pathname === "/" ? "white" : "white";

  const handleScroll = () => {
    if (window.scrollY > 0 && showPointer) {
      setShowPointer(false);
    } else if (window.scrollY === 0 && !showPointer) {
      setShowPointer(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showPointer]);

  const [background, setBackground] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setBackground("white");
      } else {
        setBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="po984ntynb51re9vwe9fwe-nav-d98c49w position-sticky z-index-sticky top-0">
      <div
        className="row as-dadqnf-gnmyumt-nfdbv84ds"
        style={{ position: "absolute", width: "100%" }}
      >
        <div className="col-12">
          <nav
            className={`navbar navbar-expand-lg top-0 z-index-fixed position-absolute w-100 ${
              scrolling || togglerOpen ? "navbar-white" : ""
            }`}
            style={{
              boxShadow: "none",
              background: background,
              backdropFilter: "blur(15px)",
              left: "0",
            }}
          >
            <div className="container-fluid px-0">
              <Link to="/">
                <span
                  className="navbar-brand mb-0 h1"
                  style={{ paddingLeft: "0.75rem" }}
                >
                  {logoImage}
                </span>
              </Link>
              <button
                className="navbar-toggler shadow-none ms-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navigation"
                aria-controls="navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ border: "1px solid black" }}
              >
                <span
                  className="navbar-toggler-icon mt-2"
                  style={{ marginLeft: "2px" }}
                >
                  <span className="navbar-toggler-bar bg-light bar1"></span>
                  <span className="navbar-toggler-bar bg-light bar2"></span>
                  <span className="navbar-toggler-bar bg-light bar3"></span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse pt-3 pb-2 py-lg-0 w-100"
                id="navigation"
              >
                <ul className="navbar-nav navbar-nav-hover ms-auto asd-asd8asd-as94dasdas984qwdqcwiocnd">
                  <li className="nav-item dropdown dropdown-hover mx-2">
                    <a
                      className="nav-link ps-2 d-flex cursor-pointer align-items-center text-dark"
                      id="dropdownMenuPages"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Sobre Nosotros
                      <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-animation ms-n3 dropdown-md p-3 border-radius-xl mt-0 mt-lg-3"
                      aria-labelledby="dropdownMenuPages"
                    >
                      <div className="d-none d-lg-block">
                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1">
                          Nosotros
                        </h6>
                        <Link
                          to="/blog"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/blog">Nuestro Blog</Link>
                        </Link>
                        <Link
                          to="/SobreNosotros"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/SobreNosotros">Sobre Nosotros</Link>
                        </Link>
                        <Link
                          Link
                          to="/contactanos"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/contactanos">Contáctanos</Link>
                        </Link>
                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1 mt-3">
                          Cuenta
                        </h6>
                        <Link
                          to="/registro"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/registro">Registrarse</Link>
                        </Link>
                        <Link
                          to="/login"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/login">Iniciar Sesión</Link>
                        </Link>
                      </div>

                      <div className="d-lg-none">
                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1">
                          NOSOTROS
                        </h6>

                        <Link
                          to="/blog"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/blog">Nuestro Blog</Link>
                        </Link>
                        <Link
                          to="/SobreNosotros"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/SobreNosotros">Sobre Nosotros</Link>
                        </Link>
                        <Link
                          to="/contactanos"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/contactanos">Contáctanos</Link>
                        </Link>

                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1 mt-3">
                          CUENTA
                        </h6>
                        <Link
                          to="/registro"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/registro">Registrarse</Link>
                        </Link>
                        <Link
                          to="/Login"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/Login">Iniciar Sesión</Link>
                        </Link>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown dropdown-hover mx-2">
                    <a
                      className="nav-link ps-2 d-flex cursor-pointer align-items-center text-dark"
                      id="dropdownMenuBlocks"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Nuestros Proyectos
                      <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-3"
                      aria-labelledby="dropdownMenuBlocks"
                    >
                      <div className="d-none d-lg-block">
                        <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href="./presentation.html"
                          >
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Page Sections
                                </h6>
                                <span className="text-sm">
                                  See all sections
                                </span>
                              </div>
                              <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                            </div>
                          </a>
                          <div className="dropdown-menu mt-0 py-3 px-2 mt-3">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/page-sections/hero-sections.html"
                            >
                              Page Headers
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/page-sections/features.html"
                            >
                              Features
                            </a>
                          </div>
                        </li>

                        <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href="./presentation.html"
                          >
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Navigation
                                </h6>
                                <span className="text-sm">
                                  See all navigations
                                </span>
                              </div>
                              <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                            </div>
                          </a>
                          <div className="dropdown-menu mt-0 py-3 px-2 mt-3">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/navigation/navbars.html"
                            >
                              Navbars
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/navigation/nav-tabs.html"
                            >
                              Nav Tabs
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/navigation/pagination.html"
                            >
                              Pagination
                            </a>
                          </div>
                        </li>

                        <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href="./presentation.html"
                          >
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Input Areas
                                </h6>
                                <span className="text-sm">
                                  See all input areas
                                </span>
                              </div>
                              <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                            </div>
                          </a>
                          <div className="dropdown-menu mt-0 py-3 px-2 mt-3">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/input-areas/inputs.html"
                            >
                              Inputs
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/input-areas/forms.html"
                            >
                              Forms
                            </a>
                          </div>
                        </li>

                        <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href="./presentation.html"
                          >
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Attention Catchers
                                </h6>
                                <span className="text-sm">
                                  See all examples
                                </span>
                              </div>
                              <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                            </div>
                          </a>
                          <div className="dropdown-menu mt-0 py-3 px-2 mt-3">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/attention-catchers/alerts.html"
                            >
                              Alerts
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/attention-catchers/modals.html"
                            >
                              Modals
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/attention-catchers/tooltips-popovers.html"
                            >
                              Tooltips & Popovers
                            </a>
                          </div>
                        </li>

                        <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href="./presentation.html"
                          >
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Elements
                                </h6>
                                <span className="text-sm">
                                  See all elements
                                </span>
                              </div>

                              <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                            </div>
                          </a>
                          <div className="dropdown-menu mt-0 py-3 px-2 mt-3">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/avatars.html"
                            >
                              Avatars
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/badges.html"
                            >
                              Badges
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/breadcrumbs.html"
                            >
                              Breadcrumbs
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/buttons.html"
                            >
                              Buttons
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/dropdowns.html"
                            >
                              Dropdowns
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/progress-bars.html"
                            >
                              Progress Bars
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/toggles.html"
                            >
                              Toggles
                            </a>
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                              href="./sections/elements/typography.html"
                            >
                              Typography
                            </a>
                          </div>
                        </li>
                      </div>

                      <div className="row d-lg-none">
                        <div className="col-md-12">
                          <div className="d-flex mb-2">
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-center align-items-center p-0">
                                  Page Sections
                                </h6>
                              </div>
                            </div>
                          </div>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/page-sections/hero-sections.html"
                          >
                            Page Headers
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/page-sections/features.html"
                          >
                            Features
                          </a>

                          <div className="d-flex mb-2 mt-3">
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Navigation
                                </h6>
                              </div>
                            </div>
                          </div>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/navigation/navbars.html"
                          >
                            Navbars
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/navigation/nav-tabs.html"
                          >
                            Nav Tabs
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/navigation/pagination.html"
                          >
                            Pagination
                          </a>

                          <div className="d-flex mb-2 mt-3">
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Input Areas
                                </h6>
                              </div>
                            </div>
                          </div>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/input-areas/inputs.html"
                          >
                            Inputs
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/input-areas/forms.html"
                          >
                            Forms
                          </a>

                          <div className="d-flex mb-2 mt-3">
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Attention Catchers
                                </h6>
                              </div>
                            </div>
                          </div>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/attention-catchers/alerts.html"
                          >
                            Alerts
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/attention-catchers/modals.html"
                          >
                            Modals
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/attention-catchers/tooltips-popovers.html"
                          >
                            Tooltips & Popovers
                          </a>

                          <div className="d-flex mb-2 mt-3">
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div>
                                <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                  Elements
                                </h6>
                              </div>
                            </div>
                          </div>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/elements/avatars.html"
                          >
                            Avatars
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/elements/badges.html"
                          >
                            Badges
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/elements/breadcrumbs.html"
                          >
                            Breadcrumbs
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/elements/buttons.html"
                          >
                            Buttons
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/elements/dropdowns.html"
                          >
                            Dropdowns
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/elements/progress-bars.html"
                          >
                            Progress Bars
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href="./sections/elements/toggles.html"
                          >
                            Toggles
                          </a>
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 ps-3 border-radius-md mb-1"
                            href=""
                          >
                            Typography
                          </a>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li className="nav-item dropdown dropdown-hover mx-2">
                    <a
                      className="nav-link ps-2 d-flex cursor-pointer align-items-center text-dark"
                      id="dropdownMenuDocs"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Contacto y Soporte
                      <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animation dropdown-md dropdown-md-responsive mt-0 mt-lg-3 p-3 border-radius-lg"
                      aria-labelledby="dropdownMenuDocs"
                    >
                      <div className="d-none d-lg-block">
                        <ul className="list-group">
                          <li className="nav-item list-group-item border-0 p-0">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              href=""
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                Por qué Elegirnos
                              </h6>
                              <span className="text-sm">
                                Control total de tu inversión con plataforma en
                                tiempo real
                              </span>
                            </a>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              href=""
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                Nuestros Proyectos
                              </h6>
                              <span className="text-sm">
                                Proyectos con acabados premium y altas tasas de
                                retorno
                              </span>
                            </a>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              href=""
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                Graham´s Project
                              </h6>
                              <span className="text-sm">
                                Redefinimos el sector inmobiliario en Castellón
                              </span>
                            </a>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              href=""
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                Nuestros Logros
                              </h6>
                              <span className="text-sm">
                                100% satisfacción de nuestros inversores
                              </span>
                            </a>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <a
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              href=""
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                Nuestros Servicios
                              </h6>
                              <span className="text-sm">
                                Asesoramiento experto durante todo el proceso de
                                inversión
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="row d-lg-none">
                        <div className="col-md-12 g-0">
                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href=""
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              Por qué Elegirnos
                            </h6>
                            <span className="text-sm">
                              Control total de tu inversión
                            </span>
                          </a>

                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href=""
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              Nuestros Proyectos
                            </h6>
                            <span className="text-sm">
                              Proyectos con acabados premium
                            </span>
                          </a>

                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href=""
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              Graham´s Project
                            </h6>
                            <span className="text-sm">
                              Redefinimos el sector inmobiliario
                            </span>
                          </a>

                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href=""
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              Nuestros Logros
                            </h6>
                            <span className="text-sm">
                              100% satisfacción de nuestros inversores
                            </span>
                          </a>

                          <a
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            href=""
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              Nuestros Servicios
                            </h6>
                            <span className="text-sm">
                              Asesoramiento experto
                            </span>
                          </a>
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li className="nav-item ms-lg-auto">
                    {/* <a
                        className="nav-link nav-link-icon me-2"
                        href="http://www.sashatechve.com"
                        target="_blank"
                      >
                        <i
                          className="fa-solid text-dark fa-s"
                          style={{ marginRight: "-10px" }}
                        ></i>
                        <i className="fa-solid text-dark fa-t m-2"></i>
                        <p
                          className="d-inline text-sm text-dark z-index-1 font-weight-bold"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="Star us on Github"
                        >
                          Sasha Tech
                        </p>
                      </a> */}
                  </li>
                  <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
                    <Link
                      to="/Login"
                      className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0"
                      style={{ minWidth: "0" }}
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fa-solid fa-right-to-bracket as-da4s9da8s4d9qd5a6s"
                      ></i>
                      <span>Inicia Sesión</span>
                    </Link>
                  </li>
                  <li className="nav-item sd4fsd65f4we8f7we98f7we98f4wef87efw4e">
                    <Link
                      to="/Registro"
                      className="btn a-sd4q98dq-sdas98d4as-btn22 mb-0"
                      style={{ minWidth: "0" }}
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fa-solid fa-right-to-bracket as-da4s9da8s4d9qd5a6s"
                      ></i>
                      <span>Registrate</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
