import React, { useState, useEffect, useContext } from "react";
import { Context } from "./store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop";
import { BackendURL } from "./component/backendURL";
import { Redirect, useNavigate } from "react-router-dom";
import axios from "axios";

import { Login } from "./pages/Login";
import injectContext from "./store/appContext";

import { Grahamproject } from "./pages/grahamproject";
import LoadingOverlay from "./pages/LadingOverlay";
import { jwtDecode } from "jwt-decode";
import { Navbar } from "./component/navbar";
import { Footer } from "./component/footer";
import { RegistroUsuarios } from "./pages/RegistroUsuarios";
import { NewRegister } from "./pages/NewRegister";
import { Contactanos } from "./pages/contactanos";
import { BlogNoticias } from "./pages/BlogNoticias";
import { SobreNosotros } from "./pages/SobreNosotros";
import { Layout_dir_terminal } from "./Rol/Dir/Layout_dir_terminal";

const Layout = () => {
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const basename = process.env.BASENAME || "";

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const token = localStorage.getItem("token");
  const prueba = localStorage.getItem("prueba");
  let userRole = null;
  if (token) {
    const decoded = jwtDecode(token);
    userRole = decoded.rol; // Aquí obtienes el rol del token
  }

  const renderLayout = () => {
    if (isLoading) {
      return <div>{isLoading && <LoadingOverlay />}</div>;
    }
    // Realmente va "userRole", solo se coloca "token" para pruebas
    switch (prueba) {
      // case "ADMIN":
      //   return <Layout_pista toggleLoading={toggleLoading} />;
      // case "SUP_TAQ_LARGAS":
      //   return <Layout_listin_rl toggleLoading={toggleLoading} />;
      // case "SUP_AVISOS":
      //   return <Layout_avisos_cobro toggleLoading={toggleLoading} />;
      // case "SUP_TAQ_RCORTA":
      //   return <Layout_listin_rc toggleLoading={toggleLoading} />;
      // case "SUP_EST":
      //   return <Layout_est toggleLoading={toggleLoading} />;
      case "DIR_TERMINAL":
        return <Layout_dir_terminal toggleLoading={toggleLoading} />;
      // case "ADMIN-SASHA":
      //   return <Layoutadmin29640206 toggleLoading={toggleLoading} />;
      // case "ASIST_DIR":
      //   return <Layout_asis_dir toggleLoading={toggleLoading} />;
      // case "EMPLOYEE_SASHATECH":
      //   return <SashaTechEmployeeLayout />;
      default:
        return (
          <div>
            <BrowserRouter basename={basename}>
              <ScrollToTop>
                <Navbar />
                <Routes>
                  <Route element={<Login />} path="/Login" />
                  <Route element={<Contactanos />} path="/contactanos" />
                  <Route element={<BlogNoticias />} path="/Blog" />
                  <Route element={<SobreNosotros />} path="/SobreNosotros" />
                  <Route element={<NewRegister />} path="/Registro" />
                  <Route element={<Grahamproject />} path="/"></Route>
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <Footer />
              </ScrollToTop>
            </BrowserRouter>
          </div>
        );
    }
  };
  return renderLayout();
};

export default injectContext(Layout);
