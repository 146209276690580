import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../styles/login.css";
import { Context } from "./../store/appContext";

export const NewRegister = () => {
  const { actions, store } = useContext(Context);
  const navigate = useNavigate();
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 7000); // Cambiar después de 7 segundos
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/AccesIntravialca",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const userData = response.data;
        actions.savetoken(response.data.token, userData);

        navigate("/Inicio");
      } else {
        console.error("Error al iniciar sesión", response.data);
      }
    } catch (error) {
      mostrarAlerta2();
      console.error("Error al iniciar sesión", error);
    }
  };

  const mostrarAlerta1 = () => {
    swal({
      title: "Inicio de sesión exitoso",
      icon: "success",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  const mostrarAlerta2 = () => {
    swal({
      title: "Correo o Contraseña Invalidad",
      icon: "success",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  return (
    <div>
      <div className="aq4-asd56sa54s">
        <div className="sa65d489as7f98re7f98q489re7w9d8q4wd9q8w7d98rwe74we w-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="sd45erge98h88r48ter9q4w9q87wd9q8wdqw98"
          >
            <path
              fill="#ffffff"
              fillOpacity="1"
              d="M0,160L80,154.7C160,149,320,139,480,138.7C640,139,800,149,960,165.3C1120,181,1280,203,1360,213.3L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div className="asd564er987ty98r79t8ut7yt9rv8wc4q9d8e4r98we4">
          <div className="container containerDeLoginjssegundo1234">
            <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
            <p style={{ color: "#FF5B1B" }}>Registrate en grahamproject.uk</p>
            <form onSubmit={handleSubmit} className="formularioDeLogin">
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="first_name"
                    required
                    // onChange={handleChange}
                    // value={formData.first_name}
                  />
                  <label>Nombre</label>
                </div>
                <div className="input-group-login">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="last_name"
                    required
                    // onChange={handleChange}
                    // value={formData.last_name}
                  />
                  <label>Apellido</label>
                </div>
                <div className="input-group-login">
                  <input
                    className="inputDeLogin"
                    type="email"
                    name="email"
                    required
                    // onChange={handleChange}
                    // value={formData.email}
                  />
                  <label>Correo Electrónico</label>
                </div>
              </div>

              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="rol"
                    // onChange={handleChange}
                    // value={formData.rol}
                  >
                    <option value="" disabled>
                      Interés Principal
                    </option>
                    <option value="CASAS">CASAS</option>
                    <option value="APARTAMENTOS">APARTAMENTOS</option>
                  </select>
                  <label>Rol Usuario</label>
                </div>
                <div className="input-group-login">
                  <input
                    className="inputDeLogin"
                    type="number"
                    name="user_cedula"
                    // onChange={handleChange}
                    // value={formData.user_cedula}
                    required
                  />
                  <label>Cédula Usuario</label>
                </div>
                <div className="input-group-login">
                  <input
                    className="inputDeLogin"
                    type="number"
                    name="user_phone"
                    // onChange={handleChange}
                    // value={formData.user_phone}
                    required
                  />
                  <label>Teléfono Usuario</label>
                </div>
              </div>
              <button className="buttonCargadeDatosDeLogin" type="submit">
                Iniciar sesión <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
        </div>
        <div className="d65f4fg98ry7j98er7ge6t9r8g7w8e94ew">
          <div className="sd65f4r9y8u7utytr98red98ut7ytb98vqw897erwe"></div>
        </div>
      </div>
    </div>
  );
};
